<template>
    <div class="pressure-record-edit-parameter-apap">
        <div class="pressure-record-edit-parameter-apap-top">
            <div class="pressure-record-edit-parameter-apap-column" style="margin-right: 25px;">
                <record-selector title="起始压力" v-model="startPressure" :dataArray="startPressureArray"></record-selector>
                <record-selector title="最大压力" v-model="maxPressure" :dataArray="maxPressureArray"></record-selector>
                <record-selector title="呼气释压" v-model="breathPressure" :dataArray="breathPressureArray"></record-selector>
            </div>
            <div class="pressure-record-edit-parameter-apap-column">
                <record-selector style="marginTop:64px" title="最小压力" v-model="minPressure"
                    :dataArray="minPressureArray"></record-selector>
                <record-selector title="释压水平" :disabled="breathPressure == 0" v-model="pressureLevel"
                    :dataArray="pressureLevelArray"></record-selector>
            </div>
        </div>
        <common-bottom ref="bottomRef" :cureMode="cureMode" :initParamModel="initParamModel"></common-bottom>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import RecordSelectorMultiple from "./component/pop-view-holography-case-edit-parameter-selector-multiple.vue";
import CommonBottom from "./bottom.vue";
import { pressureArray, pressureLevelArray, breathPressureArray } from "@js/parameter-option-data-source.js";
export default {

    components: {
        RecordSelector,
        RecordSelectorMultiple,
        CommonBottom
    },

    props: {
        initParamModel: Object
    },

    data() {
        return {
            cureMode: CURE_MODE.APAP,
            paramModel: undefined,
            //选中项
            startPressure: undefined,
            minPressure: undefined,
            maxPressure: undefined,
            pressureLevel: undefined,
            breathPressure: undefined,
            //选项数组
            pressureLevelArray: pressureLevelArray,
            breathPressureArray: breathPressureArray,
            //ref
            bottomRef:undefined
        }
    },

    computed: {
        //选项数组
        startPressureArray() {
            return pressureArray(4, this.minPressure)
        },

        minPressureArray() {
            return pressureArray(this.startPressure, this.maxPressure)
        },

        maxPressureArray() {
            return pressureArray(this.minPressure, 20)
        },

        jsonStr() {
            return JSON.stringify({
                cureMode: this.cureMode,
                startPressure: this.startPressure * 10,
                apapMaxP: this.maxPressure * 10,
                apapMinP: this.minPressure * 10,
                depType: this.breathPressure,
                depLevel: this.pressureLevel,
                cureDelay: this.$refs.bottomRef.delayPressure,
                humidifyLevel: this.$refs.bottomRef.wetLevel,
                intelligentStart: this.$refs.bottomRef.smartStart,
                intelligentStop: this.$refs.bottomRef.smartStop,
                leakAlarm: this.$refs.bottomRef.leakRemindTime,
                maskTypeId: this.$refs.bottomRef.maskType,
                mask: this.$refs.bottomRef.maskCategory,
                pipe: this.$refs.bottomRef.tubeType,
                heattubeTem: this.$refs.bottomRef.tubeType == 0 ? 0 : this.$refs.bottomRef.heatTubeTemperature
            })
        }
    },

    async created() {
        this.paramModel = { ...this.initParamModel }
        if (this.paramModel && this.paramModel.cureMode == this.cureMode) {
            this.startPressure = parseFloat(this.paramModel.startPressure / 10)
            this.maxPressure = parseFloat(this.paramModel.apapMaxP / 10)
            this.minPressure = parseFloat(this.paramModel.apapMinP / 10)
            this.breathPressure = parseInt(this.paramModel.depType)
            this.pressureLevel = parseInt(this.paramModel.depLevel)
        } else {
            this.paramModel.cureMode = this.cureMode
            this.startPressure = 4
            this.maxPressure = 14
            this.minPressure = 6
            this.breathPressure = 1
            this.pressureLevel = 1
        }
    },

    mounted() {
        this.bottomRef = this.$refs.bottomRef
    }

}
</script>

<style lang="scss" scoped>
.pressure-record-edit-parameter-apap {
    &-top {
        display: flex;
    }

    &-column {
        width: 50%;
    }
}
</style>